/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";

import Layout from "../components/Layout";

import { DatenschutzPageTemplate } from "../templates/datenschutz-page";

const DatenschutzPage = ({ data }) => {
    const html = data.markdownRemark.html;
    return (
        <Layout>
            <DatenschutzPageTemplate html={html} />
        </Layout>
    );
};

export default DatenschutzPage;

export const pageQuery = graphql`
	query datenschutzPageQuery {
		markdownRemark(frontmatter: { templateKey: { eq: "datenschutz-page" } }) {
			html
		}
	}
`;
