/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

export const DatenschutzPageTemplate = ({ html }) => {
	return (
		<React.Fragment>
			<section sx={{ variant: "spacing" }}>
				<div
					sx={{
						px: [2, 4, 6],
						maxWidth: "800px",
						margin: "0 auto",
						h1: {
							fontSize: [7, 7, 7],
							mt: "0px",
							mb: [3, 4, 4],
							span: {
								display: ["inline", "none", "none"],
							},
						},
						h2: {
							variant: "text.body",
							fontSize: [4, 5, 5],
							fontWeight: "lightbold",
							mb: 1,
						},
						h3: {
							variant: "text.body",
							fontSize: [3, 5, 5],
							fontWeight: "lightbold",
							mb: 1,
							mt: 3,
						},
						h4: {
							variant: "text.body",
							fontSize: [2, 4, 4],
							fontWeight: "lightbold",
							mb: 1,
							mt:3
						},
						p: { mb: 1, mt: "0px", fontSize: [2, 4, 4] },
					}}
					dangerouslySetInnerHTML={{ __html: html }}
				/>
			</section>
		</React.Fragment>
	);
};
